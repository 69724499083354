.App {
  font-family: sans-serif;
  text-align: center;
}

.highlighted-beat {
  background-color: #facc15 !important; /* bright yellow with !important */
  transition: background-color;
}

.measure-heading {
  position: sticky;
  top: 60px; /* Adjust this so it sits below your floating Start/Reset button */
  background-color: #f9f9f9; /* or white, so it doesn't overlap text */
  z-index: 5; /* keep it above other elements while scrolling */
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.tutorial-highlight {
  position: relative;
  z-index: [9998];
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.8),
    /* Bright blue border */ 0 0 15px 5px rgba(59, 130, 246, 0.6),
    /* Outer glow */ 0 0 0 9999px rgba(0, 0, 0, 0.3); /* Much lighter overlay */
  border-radius: 4px;
  transition: all 0.3s ease;
  pointer-events: auto;
}

/* Ensure highlighted elements remain interactive */
.tutorial-highlight * {
  pointer-events: auto;
}